<script lang="ts" setup>
import Layout3 from '@/components/layouts/Layout3.vue'
import SiteHeader from '@/components/SiteHeader.vue'

// Reset userHasJustBeenDeleted because it was only set for this page to display
sessionStorage.setItem('userHasJustBeenDeleted', 'false')
</script>

<template>
  <v-row class="d-flex flex-column" no-gutters>
    <site-header />

    <layout-3
      name="bye"
      :title="$t('goodbye.title')"
      :subtitle="`
        <p>${$t('goodbye.text.upper')}</p>
        <p class='mb-0'>${$t('goodbye.text.lower')}</p>`"
      :image="{ src: 'deleted-skribble-feedback', width: 414, height: 402 }"
    >
      <v-btn class="ma-0" color="info" large :href="$t('goodbye.cta.href')">
        {{ $t('goodbye.cta.label') }}
      </v-btn>
    </layout-3>
  </v-row>
</template>
